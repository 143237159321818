<template>
  <div class="gallery-root slider-images-navigation-button">
    <VCupertino
      v-if="isModalOpen && initContent.length > 0"
      :is-show="isModalOpen"
      :drawer-options="{
        modal:true,
        cssClass:'gallery-cupertino',
        buttonDestroy:false,
      }"

      prop-target-class="container"

      @willPresent="initCupertino"
      @backdropTap="closeGallery"
      @didDismiss="closeGallery"
    >
      <div class="gallery-swiper__root">
        <div class="thumbs">
          <swiper
            :direction="'horizontal'"
            :space-between="0"
            :slides-per-view="initContent.length"
            :free-mode="true"
            :watch-slides-progress="true"
            :modules="modules"
            class="gallery-thumbs-swiper"
            :breakpoints="{
              860:{
                direction:'vertical',
                slidesPerView:9,
                spaceBetween:10
              }
            }"

            @swiper="galleryThumbsSlider.setSliderRef"
          >
            <swiper-slide
              v-for="img in initContent"
              :key="`gallery-slade-key-${img.url}`"
            >
              <NuxtImg
                v-if="img.type === 'img'"
                :src="String(img.url)"
                loading="lazy"
                placeholder
                width="144"
                height="78"
                fit="default"
              />
              <div
                v-if="img.type === 'video'"
                class="video-preview"
              >
                <img
                  v-if="getLinkForPreview(img.source!) && !img.preview"
                  class="preview"
                  :src="getLinkForPreview(img.source!)"
                  alt="img"
                >
<!--                <video-->
<!--                  v-else-->
<!--                  class="preview"-->
<!--                  style="object-fit: cover"-->
<!--                >-->
<!--                  <source :src="img.url" />-->
<!--                </video>-->
                <nuxt-img
                  v-if="!getLinkForPreview(img.source!) && img.preview"
                  class="preview"
                  :src="String(img.preview)"
                  alt="preview-image"
                />
                <img
                  class="play-icon"
                  alt="play"
                  src="~/assets/img/svg/gallery/play.svg"
                >
              </div>

              <template v-if="img.hint">
                <div
                  v-html="img.hint"
                  class="collection-slider-images__pagination-slide-hint --gray"
                />
              </template>
            </swiper-slide>
          </swiper>
        </div>
        <div class="main">
          <div
            class="close-gallery destroy-button"
            @click="closeCupertino"
          >
            <img
              src="~/assets/img/svg/common/close.svg"
              aria-level="close"
              alt="close"
            />
          </div>
          <swiper
            :loop="Boolean(initContent.length > 1)"
            :speed="500"
            :navigation="true"
            :modules="modules"
            :space-between="20"
            :watch-overflow="true"
            :initial-slide="0"
            :slides-per-view="1"

            :virtual="false"
            :thumbs="{ swiper: galleryThumbsSliderRef }"
            :pagination="{ enabled: true, type: 'fraction' }"
            class="gallery-main-swiper"
            :breakpoints="{
              860:{
                spaceBetween: 0,
                pagination:{
                  enabled:true,
                  type: 'fraction'
                }
              }
            }"
            @slideChange="changeActiveSlide"
            @swiper="initSwiper"
          >
            <swiper-slide
              v-for="(img, index) in initContent"
              :lazy="false"
              :virtual-index="index"
              :key="`main-gallery-slide-${img.url}`"
            >
              <NuxtImg
                v-if="img.type === 'img' && !isShowDots"
                :src="String(img.url)"
                loading="lazy"
                width="1423"
                fit="max"
                decoding="auto"
                :alt="`product-image-${img.url}`"
              />
              <template v-if="img.type==='img' && isShowDots">
                <div style="position: relative; width: 1423px;">
                  <nuxt-img
                    :src="String(img.url)"
                    width="1423"
                    height="999"
                    fit="default"
                    :modifiers="{'border': 'default'}"
                    :loading="Boolean(index > 0) ? 'lazy' : 'eager'"
                    decoding="auto"
                    :alt="`product-image-${img.url}`"
                  />
                  <template v-if="$viewport.isGreaterOrEquals('lg')">
                    <template v-if="dotsForProductCollections && isShowDots">
                      <ProductDot
                        v-for="(item) in dotsForProductCollections.productSetImageDots"
                        :key="`ProductDot-${item.product_id}`"
                        :info="item"
                        :is-product-page="true"
                      />
                    </template>
                  </template>
                </div>
              </template>
              <iframe
                v-if="img.type === 'video'"
                allowfullscreen
                frameborder="0"
                width="3300"
                height="100%"
                :src="img.url"
              />
            </swiper-slide>
          </swiper>
          <div
            v-if="!$viewport.isGreaterOrEquals('lg')"
            class="gallery-swiper__mobile-orientation-help"
          >
            <img
              src="~/assets/img/svg/gallery/mobile-phone-orientation.svg"
              alt="переворот телефона"
            />
            <span>Смотреть удобнее, если повернуть телефон</span>
          </div>

          <template v-if="slideHint">
            <div class="gallery-swiper__slide-hint">
              {{ slideHint }}
            </div>
          </template>
        </div>
        <div
          class="gallery-swiper__backdrop"
          @click="closeCupertino"
        >
        </div>
      </div>
    </VCupertino>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Navigation, Thumbs, Virtual, Pagination } from 'swiper/modules';
import type { Swiper as SwiperClass } from 'swiper/types';
import type { CupertinoPane } from 'cupertino-pane';
import useGalleryComponent from '~/composables/useGallery';
import VCupertino from '~/components/widget/VCupertino.vue';
import { setCustomSwiper } from '~/utils/swiper/setCustomSwiper';
import { concatMediaContent } from '~/utils/product/concatMediaContent';
import { useViewport } from '#imports';
import { computed, type ComputedRef, ref } from 'vue';

const ProductDot = defineAsyncComponent(() => import('~/components/collection/ProductDot.vue'));

const isModalOpen = ref<boolean>(false);
const cupertinoRef = ref<CupertinoPane | null>(null);
const { galleryState, closeGallery } = useGalleryComponent();
const { concatArrays, initMediaArrays, getLinkForPreview } = concatMediaContent();

const $route = useRoute();
const $viewport = useViewport();

const galleryMainSliderRef = ref<SwiperClass | null>(null);
const galleryMainSlider = setCustomSwiper(galleryMainSliderRef);

const galleryThumbsSliderRef = ref<SwiperClass | null>(null);
const galleryThumbsSlider = setCustomSwiper(galleryThumbsSliderRef);

const modules = [FreeMode, Navigation, Thumbs, Virtual, Pagination];

const initSwiper = (swiperInstance: SwiperClass) => {
  galleryMainSlider.setSliderRef(swiperInstance);
	galleryMainSliderRef.value!.slideToLoop(galleryState.value.indexActiveSlide!) //+ 1); // если хотим, чтобы при открытии открывался следующий слайд

	if (swiperInstance) {
	  swiperInstance.slideToLoop(galleryState.value.indexActiveSlide!) //+ 1); // если хотим, чтобы при открытии открывался следующий слайд
	}
};

const initCupertino = (cupertino: CupertinoPane | null) => {
  cupertinoRef.value = cupertino;
};

const closeCupertino = async () => {
  await cupertinoRef.value?.destroy({ animate: true });
};

const initContent: any = ref([]);
const onInitContent = () => {
  const { contentVideo, contentImg } = initMediaArrays({
    videos: galleryState.value.videos,
    imagesIds: galleryState.value.imagesIds
  });

  let _items = concatArrays(contentImg, contentVideo);

  const _product: any = galleryState.value?.product;
  const _products: any = galleryState.value?.products;
  const userImagesIds = [...(_products?.dynamicFields?.user_images_ids || _product?.dynamicFields?.user_images_ids || [])];
  if (userImagesIds.length > 0) {
    userImagesIds.map((imageId: number) => {
      _items.push({
        type: "img",
        url: String(imageId),
        hint: "Фото от клиентов",
      })
    })
  }

  initContent.value = _items;
};

const eventChangeOrientation = () => {
  nextTick(async () => {
    await new Promise((r) => setTimeout(r, 100));
    galleryMainSliderRef.value?.virtual.update(true);
  });
};

const dotsForProductCollections = computed(() => {
  if (Array.isArray(galleryState.value.products)) {
    return galleryState.value.products.find((item: any) => item.futureImageIndex === activeSlide.value)
  } else return galleryState.value.products
});

const isShowDots = computed(() => {
  if (!galleryState.value.products || !dotsForProductCollections) {
    return false;
  }
  if (!Array.isArray(galleryState.value.products) && activeSlide.value === 1 && dotsForProductCollections.value) {
    return true;
  }
  if (Array.isArray(galleryState.value.products) && dotsForProductCollections.value) {
    return true
  }
  return false
})

const activeSlide = ref(galleryState.value.indexActiveSlide);


const changeActiveSlide = (event: SwiperClass) => {
  activeSlide.value = (event?.realIndex || 0) + 1;
  console.log('event.realIndex', event.realIndex)
};

const slideHint: ComputedRef<string> = computed(() => {
  const _activeSlide: any = activeSlide.value;
  return initContent.value?.[_activeSlide - 1]?.hint || ""
});

watch(() => $route.path, async () => {
  await closeCupertino();
  closeGallery();
});

onMounted(() => {
  isModalOpen.value = true;
  onInitContent();
  addEventListener('orientationchange', eventChangeOrientation);
});
onBeforeUnmount(() => {
  removeEventListener('orientationchange', eventChangeOrientation);
});
</script>

<style scoped lang="scss">
.gallery-root {
	width: 100%;
	min-width: 100%;

	& .video-preview {
		position: relative;
		width: 100%;
		height: 100%;

		& > .preview {
			width: 100%;
			height: 100%;
		}
		& > .play-icon {
			position: absolute;
			top: 50%;
			left: 50%;

			transform: translate(-50%, -50%);
		}
	}

	& .close-gallery {
		position: absolute;
		top: 24px;
		right: 24px;
		z-index: 10;
    background-color: #F4F3F1;
	}

	& .gallery-swiper__root {
		min-height: 90vh;
		max-height: 90vh;
		width: 100%;

		display: flex;
		gap: 24px;

		& .main {
			flex: 1;
			display: flex;
			position: relative;
			z-index: 10;
		}

		& .thumbs {
			width: 144px;
			min-width: 144px;
			display: flex;
			position: relative;
			z-index: 10;
		}
	}

	& .gallery-main-swiper {
		background: #FFF;
		border-radius: 15px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			width: auto;
			height: auto;
      object-fit: contain;
		}

		.swiper-zoom-container {
			background: #FFF;
			z-index: -1;
		}

		&:deep(.swiper-slide) {
			display: flex;
			justify-content: center;
			border-radius: 15px;
			overflow: hidden;

			& img {
				mix-blend-mode: multiply;
			}
		}
	}

	& .gallery-thumbs-swiper {
		max-height: 91%;
		width: 100%;

		&:deep(.swiper-slide) {
			position: relative;
			overflow: hidden;
			height: 78px !important;
			border-radius: 5px;
			cursor: pointer;
			width: 100%;
      background-color: #FFF;

			&::before {
				content: "";
				position: absolute;
				z-index: 3;
				inset: -1px;
				background-color: rgba(255, 255, 255, 0.5);
				transition: all 0.2s;
			}

			& > img,
			& > .video-preview {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&::after {
				content: "";
				float: left;
				padding-top: 55.29%;
			}

			&:hover {
				&:before {
					background-color: rgba(255, 255, 255, 0.1);
				}
			}

			&.swiper-slide-thumb-active:before {
				background-color: rgba(255, 255, 255, 0);
			}

			& > img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	&:deep(.gallery-cupertino) {
		z-index: 100;

		& .pane {
			width: 100%;
			z-index: 100;

			min-width: initial;
			max-width: initial;

			background: transparent;
			border: none;
			border-radius: 0;
			margin: 0;
		}

		& .backdrop {
			background: rgba(41, 41, 41, 0.80);
			backdrop-filter: blur(3.5px);
		}
	}
}
.gallery-swiper__slide-hint {
  position: absolute;
  z-index: 2;
  top: 24px;
  left: 24px;
  cursor: pointer;
  border-radius: 10px;
  background: #F4F3F1;
  padding: 13px 24px;
  box-sizing: border-box;
  color: #252525;
  font-size: 12px;
  line-height: 100%;
  font-weight: 600;
}

@media (max-width: 859px) {
  .gallery-root .gallery-main-swiper .swiper-zoom-container {
    padding: 0;
    background: transparent;
  }
}
</style>
<style lang="scss">
.gallery-root {
  &.slider-images-navigation-button .swiper-button-next::after,
  &.slider-images-navigation-button .swiper-button-prev::after {
    background-color: #F4F3F1;
  }

  .swiper-pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    background-color: #F4F3F1;
  }
}
.gallery-main-swiper {
	& .swiper-pagination-fraction {
		width: fit-content;
		font-style: normal;
		top: initial;
		left: initial;

    position: absolute;
    z-index: 2;
    bottom: 24px;
    right: 24px;
    pointer-events: none;
    padding: 13px 24px;
    border-radius: 10px;
    color: black;
    font-size: 12px;
    line-height: 100%;
    font-weight: 600;
    background: rgba(255, 255, 255, 0.7);
	}
}

.gallery-swiper__backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	cursor: pointer;
}

.gallery-swiper__mobile-orientation-help {
	display: none;
}

@media (max-width: 859px) {
	.gallery-root {
		.gallery-cupertino .pane {
			max-height: initial !important;
			width: 100% !important;
			transform: initial !important;
			height: 100% !important;
			padding: 0;
		}

		.gallery-cupertino .target-panel {
			height: 100% !important;
		}

		.close-gallery {
			top: 20px !important;
			right: 0 !important;
		}

    &.slider-images-navigation-button {
      .swiper-button-prev:after {
        margin-right: -25px;
      }
      .swiper-button-next:after {
        margin-left: -25px;
      }
    }
	}
	.gallery-root .gallery-swiper__root {
		height: 100%;
		max-height: initial !important;
	}
	.gallery-swiper__root .thumbs {
		display: none !important;
	}
	.gallery-root .gallery-main-swiper {
		background: transparent !important;
		margin: 0 -20px;
		padding: 0 20px;
		width: calc(100% + 40px) !important;

		&:after {
			content: "";
		}
	}
	.gallery-root .gallery-main-swiper .swiper-slide {
		align-items: center;
	}
	.gallery-root .gallery-main-swiper .swiper-slide > * {
		height: initial;
		border-radius: 4px;
		position: relative;
		overflow: hidden;
		padding: 20px;
		box-sizing: border-box;
		pointer-events: none;

		img {
			max-width: 100%;
			max-height: calc(100vh - 360px);
			//border-radius: 4px;
		}
	}

	.gallery-swiper__mobile-orientation-help {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 24px;
		left: 0;
		right: 0;
		pointer-events: none;

		img {
			width: 48px;
			height: 48px;
			object-fit: contain;
			object-position: center;
			margin-bottom: 16px;
		}

		span {
			max-width: 240px;
			font-weight: 500;
			font-size: 16px;
			line-height: 140%;
			text-align: center;
			color: #FFFFFF;
			opacity: 0.5;
		}
	}
	.gallery-main-swiper .swiper-pagination-fraction {
		display: none;
	}

	@media (orientation: landscape) {
		.gallery-root .gallery-swiper__root {
			min-height: calc(100vh - 40px) !important;
		}
		.gallery-root {
			.container {
				max-width: initial !important;
			}
		}
		.gallery-root .close-gallery {
			top: 16px !important;
			right: 16px !important;
			width: 32px;
			height: 32px;
			padding: 6px;
			background: white;
		}
		.gallery-swiper__mobile-orientation-help {
			display: none;
		}
		.gallery-root .gallery-main-swiper .swiper-slide > * {
			padding: 28px 20px;
		}
		.gallery-root .gallery-main-swiper .swiper-slide > * img {
			max-height: calc(100vh - 80px);
		}
		.gallery-root .gallery-cupertino .target-panel {
			padding: 20px !important;
			overflow: hidden;
		}
		.gallery-swiper__root .swiper-pagination-fraction {
			display: block;
			bottom: 16px !important;
			right: 36px;
			padding: 9px 19px;
			line-height: 100%;
		}
		.gallery-root .gallery-main-swiper .swiper-slide {
			border-radius: 8px !important;
		}
	}
}
</style>
